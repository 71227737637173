import qs from 'qs';
import axios from 'axios';
import router from '@/router/index'
// import { requestUrl } from '@/config/index';
import { ElMessage,ElLoading } from 'element-plus';
// axios.defaults.timeout = 30*1000;

export function creates(obj: {url:string, method:string, data?:object, params?:object }){
  return new Promise((resolve,reject) => {
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    axios.request({
      url: obj.url,
      method: obj.method || 'GET',
      // baseURL: requestUrl,
      headers: {
        'authorization': sessionStorage.getItem('accountInfo') ? JSON.parse(sessionStorage.getItem('accountInfo') || '').authorization : '',
        // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      data: obj.data,
      params: obj.params
    }).then((data:any) => {
      loading.close();
      if(data.data.return_code == 200){
        resolve(data.data.data)
      }
      else if(data.data.return_code == 403){
        sessionStorage.removeItem('accountInfo')
        sessionStorage.removeItem('checkCompany')
        router.push('/login')
      }
      else{
        ElMessage(data.data.return_msg);
        reject(data.data)
      }
    }).catch(err => {
      console.log(err)
      loading.close();
      ElMessage(err.message);
      reject(err)
    })
  })
}