import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { uploadUrl, downUrl } from '@/config/index';

import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.config.globalProperties.$uploadUrl = uploadUrl;
app.config.globalProperties.$downUrl = downUrl;

app.use(router)
app.use(ElementPlus, {
  locale: zhCn,
})

app.mount('#app')
