import { creates } from './index';
import { OPUrl } from '@/config/index';

//获取品牌列表
export function getBrandList(params: object){
  return creates({
    url: OPUrl + '/admin/brand/getBrandList',
    method: 'GET',
    params: params
  })
}

//获取品牌详情
export function getBrandDetail(params: object){
  return creates({
    url: OPUrl + '/admin/brand/getBrandDetail',
    method: 'GET',
    params: params
  })
}

//获取树状菜单列表
export function getMenuList(){
  return creates({
    url: OPUrl + '/admin/brand/getMenuList',
    method: 'GET'
  })
}

//新增品牌
export function addBrand(data:object){
  return creates({
    url: OPUrl + '/admin/brand/addBrand',
    method: 'POST',
    data: data
  })
}

//编辑品牌
export function editBrand(data:object){
  return creates({
    url: OPUrl + '/admin/brand/editBrand',
    method: 'POST',
    data: data
  })
}

//删除品牌
export function delBrand(params:object){
  return creates({
    url: OPUrl + '/admin/brand/delBrand',
    method: 'GET',
    params: params
  })
}

//获取品牌下的门店列表
export function getStoreList(params:object){
  return creates({
    url: OPUrl + '/admin/brand/getStoreList',
    method: 'GET',
    params: params
  })
}

//新增门店
export function addStore(data:object){
  return creates({
    url: OPUrl + '/admin/brand/addStore',
    method: 'POST',
    data: data
  })
}

//编辑门店
export function editStore(data:object){
  return creates({
    url: OPUrl + '/admin/brand/editStore',
    method: 'POST',
    data: data
  })
}

//删除门店
export function delStore(params:object){
  return creates({
    url: OPUrl + '/admin/brand/delStore',
    method: 'GET',
    params: params
  })
}

//获取角色列表
export function getRolesList(params:object){
  return creates({
    url: OPUrl + '/admin/user/getRolesList',
    method: 'GET',
    params: params
  })
}

//编辑角色
export function editRoles(data:object){
  return creates({
    url: OPUrl + '/admin/user/editRoles',
    method: 'POST',
    data: data
  })
}

//获取角色详情
export function getRolesDetail(params:object){
  return creates({
    url: OPUrl + '/admin/user/getRolesDetail',
    method: 'GET',
    params: params
  })
}

//创建角色
export function addRoles(data:object){
  return creates({
    url: OPUrl + '/admin/user/addRoles',
    method: 'POST',
    data: data
  })
}

//删除角色
export function delRoles(params:object){
  return creates({
    url: OPUrl + '/admin/user/delRoles',
    method: 'GET',
    params: params
  })
}

//获取用户列表
export function getPersonList(params:object){
  return creates({
    url: OPUrl + '/admin/user/getPersonList',
    method: 'GET',
    params: params
  })
}

//添加员工
export function addPerson(data:object){
  return creates({
    url: OPUrl + '/admin/user/addPerson',
    method: 'POST',
    data: data
  })
}

//获取员工详情
export function getPersonDetail(params:object){
  return creates({
    url: OPUrl + '/admin/user/getPersonDetail',
    method: 'GET',
    params: params
  })
}

//编辑员工
export function editPerson(data:object){
  return creates({
    url: OPUrl + '/admin/user/editPerson',
    method: 'POST',
    data: data
  })
}

//删除员工
export function delPerson(data:object){
  return creates({
    url: OPUrl + '/admin/user/delPerson',
    method: 'POST',
    data: data
  })
}

//修改密码
export function updatePassword(data:object){
  return creates({
    url: OPUrl + '/admin/user/updatePassword',
    method: 'POST',
    data: data
  })
}