<template>
  <div id="nav">
    <router-view/>
  </div>
</template>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html,body,div,p,span,label,ul,li{
    margin: 0;
    padding: 0;
  }
  li{
    list-style: none;
  }
  html,body,#app,#nav{
    width: 100%;
    height: 100%;
  }
  .flex1{
    flex: 1;
  }
  a:hover, a:visited, a:active, a:link{
    color: #ffffff;
    text-decoration: none;
  }
</style>
