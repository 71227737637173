import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue';
import setHome from '@/views/setting/setHome.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/setHome',
    name: 'setHome',
    component: setHome,
    children: [
      {
        path: '/brandManager',
        name: 'brandManager',
        component: () => import('../views/systemSet/brandManager.vue')
      },
      {
        path: '/roleManager',
        name: 'roleManager',
        component: () => import('../views/systemSet/roleManager.vue')
      },
      {
        path: '/staffManager',
        name: 'staffManager',
        component: () => import('../views/systemSet/staffManager.vue')
      },
      {
        path: '/marketManager',
        name: 'marketManager',
        component: () => import('../views/smartMarket/marketManager.vue')
      },
      {
        path: '/changeSkin',
        name: 'changeSkin',
        component: () => import('../views/smartMarket/changeSkin.vue')
      },
      {
        path: '/songScreen',
        name: 'songScreen',
        component: () => import('../views/smartMarket/songScreen.vue')
      },
      {
        path: '/appletsCoupon',
        name: 'appletsCoupon',
        component: () => import('../views/smartMarket/appletsCoupon.vue')
      },
      {
        path: '/numCard',
        name: 'numCard',
        component: () => import('../views/smartMarket/numCard.vue')
      },
      {
        path: '/servePeo',
        name: 'servePeo',
        component: () => import('../views/moveTV/servePeo.vue')
      },
      {
        path: '/taskManage',
        name: 'taskManage',
        component: () => import('../views/moveTV/taskManage.vue')
      }
      
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeResolve((to:any) => {
  if(!sessionStorage.getItem('accountInfo') && to.path!='/login'){
    router.push('/login')
    return false;
  }
})

export default router
