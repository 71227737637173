import { creates } from './index';
import { OPUrl } from '@/config/index';

//登录
export function login(data: object){
  return creates({
    url: OPUrl + '/admin/login/login',
    method: 'POST',
    data: data
  })
}

//退出
export function loginOut(){
  return creates({
    url: OPUrl + '/admin/login/loginOut',
    method: 'POST'
  })
}
